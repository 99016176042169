import React from "react"

import { Text } from "@blueprintjs/core"
import InfoBlock from "../global/InfoBlock/InfoBlock"
import { Link } from "gatsby"
import ProLayout from "../layout/ProLayout/ProLayout"

const TermsService = () => {
  return (
    <ProLayout>
      <InfoBlock
        headline="Terms of Service"
        body={
          <>
            <Text tagName="p">
              Welcome to Climate Check®. We offer the ​first definitive,
              easy-to-use property rating service to score real estate based on
              expected changes to the world’s climate. ​We have created and
              managed an online website and certain applications (“App” or
              “Apps”) and related support and hosting services (the “Service” or
              “Services”).
            </Text>
            <Text tagName="p">
              Climate Check, Inc. (“Company”, “we” or “us”) offers various
              services to you through our web site (
              <a
                href="https://www.climatecheck.com"
                target="_blank"
                rel="noopener"
              >
                www.climatecheck.com
              </a>
              ) and our application on the web and on mobile devices, all of
              which are ​conditioned on your agreement to adhere to the
              following Terms of Service without modification of any kind. Your
              use of the Service and/or your registration with us constitutes
              your agreement to these Terms of Service. These Terms of Service
              are subject to change at any time, without prior notice. Any
              changes that are made to these Terms of Service will not apply
              retroactively and will not apply to disputes or events occurring
              before the change is published. You are responsible for reviewing
              these Terms of Service on a regular basis. These Terms of Service
              apply to all visitors and all who access our website or Services.
            </Text>
            <Text tagName="p">
              PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND
              EACH PROVISION. THIS AGREEMENT CONTAINS A MANDATORY INDIVIDUAL
              ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT
              REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
              DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
            </Text>
            <Text tagName="p">
              We respect the privacy and security of our Users. You understand
              that by using our Services, you give consent to the collection,
              use and disclosure of our personally identifiable information as
              well as any non-personally identifiable information, as described
              in more detail in our{" "}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </Text>
            <Text tagName="p">
              You affirm that you are more than 18 years of age and are fully
              able and competent to enter into the terms, conditions,
              obligations, affirmations, representations, and warranties set
              forth in these Terms of Service, and to abide by and comply with
              these Terms of Service.{" "}
              <strong>
                If you are under 18 years of age, then please do not use our
                website, app or our Service.
              </strong>
            </Text>
            <Text tagName="p">
              By connecting to us with a third-party service (e.g., Facebook or
              Twitter or LinkedIn), you give us permission to access and use
              your information from that service as permitted by that
              third-party service, and to store your log-in credentials for that
              third-party service. The Service is not available to any User who
              has been removed from the Service by us.
            </Text>
            <Text tagName="h5">Services</Text>
            <Text tagName="p">
              ClimateCheck&trade; provides a definitive, easy-to-use property
              rating service to score real estate based on expected changes to
              the world’s climate. ClimateCheck&trade; helps home buyers make
              more informed decisions by providing granular predictions about
              the impact of climate change on individual properties.
            </Text>
            <Text tagName="h5">Registration</Text>
            <Text tagName="p">
              By registering and/or participating in this Service, you agree and
              represent as follows:
            </Text>
            <ul>
              <li>
                You are of legal age and are otherwise capable of forming a
                legally binding contract;
              </li>
              <li>
                All information you submit to us or in connection with a Service
                is accurate and complete and that you will maintain and promptly
                update any profile supplied to us to ensure accuracy at all
                times;
              </li>
              <li>
                <strong>
                  You agree to be contacted via Email, SMS and text messaging by
                  us, including push notifications, and by third parties if
                  relevant, regarding our Services, our website, third party
                  notifications and/or advertisements, and requests to rate our
                  website;
                </strong>
              </li>
              <li>
                You hereby grant us permission to email or display your profile,
                picture and such other information as may be supplied by you on
                or from our website as we shall deem advisable in its sole
                determination in connection with the Service or for marketing
                purposes thereof;
              </li>
              <li>
                By using the Service, you are granting us permission to access
                your account and those messages, data, information, text,
                graphics, audio, video, photographs or other material
                (“Materials”) posted/uploaded/transmitted to or through the
                Service using your account, solely in connection with the
                provision of Services.
              </li>
            </ul>
            <Text tagName="h5">Transactions</Text>
            <Text tagName="p">
              If you wish to purchase any product or service made available
              through the Service (each such purchase, a "Transaction"), you
              need to give us certain information relevant to your Transaction
              including, without limitation, your credit card number, your
              billing address, the expiration date of your credit card and other
              information. You represent and warrant that you have the legal
              right to use any credit card(s) or other payment method(s)
              utilized in connection with any transaction. By submitting such
              information, you grant to us the right to provide such information
              to third parties for purposes of facilitating the completion of
              Transactions initiated by you or on your behalf. Verification of
              information may be required prior to the acknowledgment or
              completion of any Transaction. To process our payments, we use
              Stripe. You must agree with their terms and conditions before
              paying for any of our products or services. You may need to
              purchase some equipment to take advantage of our Services.
            </Text>
            <Text tagName="p">
              You agree that by placing an order, you are entering into a
              binding contract with us and agree to pay all charges that may be
              incurred by you or on your behalf through the website or App, at
              the price(s) in effect when such charges are incurred including,
              without limitation, all shipping and handling charges. In
              addition, you remain responsible for any taxes that may be
              applicable to your Transactions.
            </Text>
            <Text tagName="p">
              <strong>Taxes:</strong> You are solely responsible for any
              applicable state, federal or provincial taxes. Although you may
              not be charged taxes by us, you agree that you will pay any
              applicable taxes or fees to your local or state tax agency for any
              purchases or services. We are not responsible for collecting,
              transmitting, or advising on taxes, duties, or other levies by the
              government regarding your purchases.
            </Text>
            <Text tagName="h5">Responsibility For User Content</Text>
            <Text tagName="p">
              We respect the rights of third party creators and content owners
              and expect that you will do the same. Given the nature of the
              Service and the volume of information submitted, we cannot and do
              not monitor all of the Materials posted or transmitted by you and
              other third-party information providers via the Service,
              including, without limitation, any Materials posted via the
              Service. You expressly agree that we will not be liable for
              Materials. We reserve the right, but are not obligated, to remove
              content from the Service for any reason, including content that we
              believe violates these Terms or our{" "}
              <a href="#acceptableUsePolicy">Acceptable Use Policy​</a> below.
            </Text>
            <Text tagName="p">
              It is the policy of ClimateCheck® to respond to all claims of
              intellectual property infringement. We will promptly investigate
              notices of alleged infringement and will take appropriate actions
              required under the Digital Millennium Copyright Act, Title 17,
              United States Code, Section 512(c)(2) ("DMCA") and other
              applicable intellectual property laws.
            </Text>
            <Text tagName="p">
              Pursuant to the DMCA, notifications of claimed copyright
              infringement should be sent to a Service Provider's Designated
              Agent. Notification must be submitted to the following Designated
              Agent for this website:
            </Text>
            <Text tagName="p">
              ClimateCheck, Inc.
              <br />
              Attention: Legal
              <br />
              Email:{" "}
              <a href="mailto:info@climatecheck.com">info@climatecheck.com</a>
            </Text>
            <Text tagName="p">
              1400 Village Square Blvd #3-81914
              <br />
              Tallahassee, FL 32313-7500
            </Text>
            <Text tagName="p">
              To be effective, the notification must be a written communication
              that includes the following:
            </Text>
            <ul>
              <li>
                A physical or electronic signature of a person authorized to act
                on behalf of the owner of an exclusive right that is allegedly
                infringed;
              </li>
              <li>
                Identification of the copyrighted work claimed to have been
                infringed, or, if multiple copyrighted works at a single online
                site are covered by a single notification, a representative list
                of such works at that site;
              </li>
              <li>
                Identification of the material that is claimed to be infringing
                or to be the subject of infringing activity and that is to be
                removed or access to which is to be disabled, and information
                reasonably sufficient to permit the service provider to locate
                the material;
              </li>
              <li>
                Information reasonably sufficient to permit the service provider
                to contact the complaining party, such as an address, telephone
                number and, if available, an electronic mail address at which
                the complaining party may be contacted;
              </li>
              <li>
                A statement that the complaining party has a good-faith belief
                that use of the material in the manner complained of is not
                authorized by the copyright owner, its agent or the law;
              </li>
              <li>
                A statement that the information in the notification is accurate
                and, under penalty of perjury, that the complaining party is
                authorized to act on behalf of the owner of an exclusive right
                that is allegedly infringed.
              </li>
            </ul>
            <div id="acceptableUsePolicy" />
            <Text tagName="h5">Acceptable Use Policy</Text>
            <Text tagName="p">
              The following is a partial list of the kinds of activities that
              are prohibited on or through the Service: (a) submitting Materials
              that are patently offensive to the online community, such as
              content that promotes racism, bigotry, hatred or physical harm of
              any kind against any group or individual; (b) engaging in
              activities or submitting Materials that could be harmful to
              minors; (c) engaging in activity or submitting Materials that
              harasses or advocates harassment of another person; (d) engaging
              in activity that involves the transmission of "junk mail" or
              unsolicited mass mailing or "spam" or harvesting or otherwise
              collecting personally identifiable information about Service
              users, including names, phone numbers, addresses, email addresses,
              (collectively, "User Data") without their consent; (e) engaging in
              activity, or submitting Materials, or promoting information that
              is false, misleading or promotes illegal activities or conduct
              that is abusive, threatening, obscene, defamatory or libelous; (f)
              submitting Materials that contain restricted or password only
              access pages, or hidden pages or images; (g) submitting Materials
              that displays pornographic or sexually explicit material of any
              kind; (h) submitting Materials that provide instructional
              information about illegal activities such as making or buying
              illegal weapons, violating someone's privacy, or providing or
              creating computer viruses; (i) submitting Materials that contain
              viruses, Trojan horses, worms, or any other similar forms of
              malware, (j) engaging in activities or submitting Materials that
              solicit passwords or personally identifiable information for
              unlawful purposes from other users; (k) engaging in unauthorized
              commercial activities and/or sales without our prior written
              consent such as advertising, solicitations, contests, sweepstakes,
              barter, and pyramid schemes; (l) using any robot, spider, other
              automatic device, or manual process to monitor, copy, or "scrape"
              web pages or the content contained in the website or for any other
              unauthorized purpose without our prior written consent; (m) using
              any device, software, or routine to interfere or attempt to
              interfere with the proper working of the Service; (n) decompiling,
              reverse engineering, or disassembling the software or attempting
              to do so; or (o) taking any action that imposes an unreasonable or
              disproportionately large load on the Service or our hardware and
              software infrastructure or that of any of its Licensors or
              Suppliers. In addition, you covenant and represent that you will
              not use the Service for any purpose other than those that are
              personal, nor will you use this Service in violation of the law or
              these Terms of Service.
            </Text>
            <Text tagName="h5">Use of Third Party Services</Text>
            <Text tagName="p">
              As a part of our Service, we may offer links to web sites operated
              by various third parties and are not responsible or liable for any
              acts or omissions created or performed by these third parties. We
              provide such links for your convenience and reference only. We do
              not operate or control in any way any information, software,
              products or services available on such web sites. Our inclusion of
              a link to a web site does not imply any endorsement of the
              services or the site, its contents, or its sponsoring
              organization.
            </Text>
            <Text tagName="h5">Mobile Apps</Text>
            <Text tagName="span" className="title">
              Mobile Application
            </Text>
            <Text tagName="p">
              We may make available an App to access the Service via a mobile
              device. To use any App, you must have a mobile device that is
              compatible with the Mobile Service. We do not warrant that any
              Mobile App will be compatible with your mobile device. We grant to
              you a non-exclusive, non-transferable, revocable license to use a
              compiled code copy of any App for one account on one mobile device
              owned or leased solely by you, for your personal, non-commercial
              use. You may not: (i) modify, disassemble, decompile or reverse
              engineer the App, except to the extent that such restriction is
              expressly prohibited by law; (ii) rent, lease, loan, resell,
              sublicense, distribute or otherwise transfer the App to any third
              party or use the App to provide time sharing or similar services
              for any third party; (iii) make any copies of the App; (iv)
              remove, circumvent, disable, damage or otherwise interfere with
              security-related features of the App, features that prevent or
              restrict use or copying of any content accessible through the App,
              or features that enforce limitations on use of the App; or (v)
              delete the copyright and other proprietary rights notices on the
              App. You acknowledge that we may from time to time issue upgraded
              versions of the App, and may automatically electronically upgrade
              the version of the App that you are using on your mobile device.
              You consent to such automatic upgrading on your mobile device, and
              agree that the terms and conditions of these Terms will apply to
              all such upgrades. Any third-party code that may be incorporated
              in the App is covered by the applicable open source or third-party
              end user license agreement, if any, authorizing use of such code.
              The foregoing license grant is not a sale of the App or any copy
              thereof, and we or its third-party licensors or suppliers retain
              all right, title, and interest in and to the App (and any copy of
              the App). You agree to comply with all United States and foreign
              laws related to use of the App and the Service. Standard carrier
              data charges may apply to your use of the App. If any of our
              Software is being acquired on behalf of the United States
              Government, then the following provision applies: Use,
              duplication, or disclosure of the Software by the U.S. Government
              is subject to restrictions set forth in this Agreement and as
              provided in DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS
              252.227-7013(c)(1)(ii) (OCT 1988), FAR 12.212(a) (1995), FAR
              52.227-19, or FAR 52.227-14 (ALT III), as applicable. The Software
              originates in the United States, and is subject to United States
              export laws and regulations. The Software may not be exported or
              re-exported to certain countries or those persons or entities
              prohibited from receiving exports from the United States. In
              addition, the Software may be subject to the import and export
              laws of other countries. You agree to comply with all United
              States and foreign laws related to use of the Software and the
              Service.
            </Text>
            <Text tagName="span" className="title">
              App from iTunes
            </Text>
            <Text tagName="p">
              The following also applies to any App you acquire from the iTunes
              Store (“iTunes-Sourced Software”): You acknowledge and agree that
              these Terms are solely between you and us, not Apple, and that
              Apple has no responsibility for the iTunes-Sourced Software or
              content thereof. Your use of the iTunes-Sourced Software must
              comply with the App Store Terms of Service. You acknowledge that
              Apple has no obligation whatsoever to furnish any maintenance and
              support services with respect to the iTunes-Sourced Software. In
              the event of any failure of the iTunes-Sourced Software to conform
              to any applicable warranty, you may notify Apple, and Apple will
              refund the purchase price for the iTunes-Sourced Software to you;
              to the maximum extent permitted by applicable law, Apple will have
              no other warranty obligation whatsoever with respect to the
              iTunes-Sourced Software, and any other claims, losses,
              liabilities, damages, costs or expenses attributable to any
              failure to conform to any warranty will be solely governed by
              these Terms of Service and any law applicable to us as provider of
              the software. You acknowledge that Apple is not responsible for
              addressing any claims of you or any third party relating to the
              iTunes-Sourced Software or your possession and/or use of the
              iTunes-Sourced Software, including, but not limited to: (i)
              product liability claims; (ii) any claim that the iTunes-Sourced
              Software fails to conform to any applicable legal or regulatory
              requirement; and (iii) claims arising under consumer protection or
              similar legislation; and all such claims are governed solely by
              these Terms of Service and any law applicable to us as provider of
              the software. You acknowledge that, in the event of any third
              party claim that the iTunes-Sourced Software or your possession
              and use of that iTunes-Sourced Software infringes that third
              party’s intellectual property rights, we, not Apple, will be
              solely responsible for the investigation, defense, settlement and
              discharge of any such intellectual property infringement claim to
              the extent required by these Terms of Service. You and we
              acknowledge and agree that Apple, and Apple’s subsidiaries, are
              third party beneficiaries of these Terms of Service as they relate
              to your license of the iTunes-Sourced Software, and that, upon
              your acceptance of the terms and conditions of these Terms of
              Service, Apple will have the right (and will be deemed to have
              accepted the right) to enforce these Terms of Service as relates
              to your license of the iTunes-Sourced Software against you as a
              third party beneficiary thereof.
            </Text>
            <Text tagName="h5">
              Representations and Warranties and Indemnification
            </Text>
            <Text tagName="p">
              You hereby represent and warrant that: (a) you have all necessary
              authority, rights and permissions to submit the Materials and
              grant the licenses described in these Terms of Service, (b) the
              Materials are accurate, current and complete, (c) the Materials
              and your use of the Service shall not infringe or misappropriate
              any copyright, trademark, trade secret, patent, or other
              intellectual property right of any third party or violate any
              other rights of third party, including, without limitation, any
              rights of privacy or publicity or any contractual rights, (d) the
              Materials and your use of the Service shall not violate any
              applicable law or regulation or cause injury to any person; (e)
              your use of the Service shall not violate any agreements between
              you and a third party.
            </Text>
            <Text tagName="p">
              You agree to indemnify, defend and hold harmless ​Company​, and
              its officers, directors, employees, agents, and contractors from
              and against any and all claims, costs, demands, damages,
              liabilities, or expenses, including, without limitation,
              reasonable attorneys' fees, arising from or related to: (a) the
              Materials you provide or approve for publication, (b) your use of
              the Service, (c) your breach of these Terms of Service, or (d) any
              actual, prospective, completed or terminated service between you
              and a third party.
            </Text>
            <Text tagName="h5">Disclaimers and Limitations</Text>
            <Text tagName="p">
              Errors in the information contained in our Service sometimes
              occur. ​Please use this site at your own risk. Information
              provided is for general guidance only and is not a guarantee. You
              should thoroughly investigate all attributes of any property
              before purchasing. ​In addition, we may make changes and
              improvements to the information provided herein at any time. THE
              SERVICE AND THE INFORMATION, SOFTWARE, PRODUCTS AND SERVICES
              ASSOCIATED WITH IT ARE PROVIDED "AS IS." WE AND/OR OUR SUPPLIERS,
              PARTNERS AND AFFILIATES DISCLAIM ANY WARRANTY OF ANY KIND, WHETHER
              EXPRESS OR IMPLIED, AS TO ANY MATTER WHATSOEVER RELATING TO THE
              SERVICE AND ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES
              PROVIDED HEREIN, INCLUDING WITHOUT LIMITATION THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, AND NONINFRINGEMENT. USE OF OUR SERVICE IS AT YOUR OWN
              RISK. WE AND/OR OUR SUPPLIERS, ARE NOT LIABLE FOR ANY DIRECT,
              INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
              OR OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
              USE OF THE SERVICE OR WITH THE DELAY OR INABILITY TO USE THE
              SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES
              OBTAINED THROUGH US, OR OTHERWISE ARISING OUT OF THE USE OF THE
              SERVICE, WHETHER RESULTING IN WHOLE OR IN PART, FROM BREACH OF
              CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR
              OTHERWISE, EVEN IF WE AND/OR OUR SUPPLIERS HAD BEEN ADVISED OF THE
              POSSIBILITY OF DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE
              EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT
              APPLY TO YOU.
            </Text>
            <Text tagName="h5">LIMITATION OF LIABILITY</Text>
            <Text tagName="p">
              IN NO EVENT SHALL WE OR OUR SUPPLIERS BE LIABLE FOR LOST PROFITS
              OR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (HOWEVER
              ARISING, INCLUDING NEGLIGENCE) ARISING OUT OF OR IN CONNECTION
              WITH THIS AGREEMENT. OUR LIABILITY, AND THE LIABILITY OF OUR
              SUPPLIERS AND AFFILIATES, TO YOU OR ANY THIRD PARTIES IN ANY
              CIRCUMSTANCE, SHALL NOT EXCEED THE TOTAL SUM OF $100.00. THE
              LIMITATIONS OF LIABILITY SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS
              OF THE CONTRACT BETWEEN US AND YOU. Some states do not allow the
              limitation of liability, so the foregoing limitation may not
              always apply.
            </Text>
            <Text tagName="h5">ERRORS AND DELAYS</Text>
            <Text tagName="p">
              We are not responsible for any errors or delays caused by an
              incorrect e-mail address provided by you or other technical
              problems beyond our reasonable control.
            </Text>
            <Text tagName="h5">Termination</Text>
            <Text tagName="p">
              We reserve the right in its sole discretion, and without any prior
              notice, to terminate your access to the Service for any reason,
              including your breach of these Terms of Service, the terms and
              conditions of any service for which you may have registered, or a
              violation of the rights of another user or the law. You may
              unsubscribe from any further communication from ​us at any time by
              delivering a written notice addressed to{" "}
              <a href="mailto:info@climatecheck.com">info@climatecheck.com</a>.
              You shall be responsible for ensuring delivery of the notice to
              us​. You may also unsubscribe by clicking the Email Preferences
              link or the opt-out link in any of our emails. We shall not be
              obligated to store any data or files for more than sixty (60) days
              after termination of your access to the Service.
            </Text>
            <Text tagName="p">
              We reserve the right with or without notice to you at any time to
              change, modify or discontinue any service or a portion or
              attribute thereof, or the offering of any information, good,
              content, product or service. We shall have no liability to you or
              any third party should we modify or discontinue any service or an
              aspect thereof.
            </Text>
            <Text tagName="h5">Dispute Resolution</Text>
            <Text tagName="p">
              Any claim or controversy arising out of or relating to the use of
              our Service, to the goods or services provided by us, or to any
              acts or omissions of other users for which you may contend we are
              liable, including but not limited to any claim or controversy
              ("Dispute"), shall be finally, and exclusively, settled by
              arbitration in Palm Beach, Florida, from which arbitration there
              shall be no appeal. The arbitration shall be held before one
              arbitrator. The arbitrator shall be selected pursuant to the AAA
              rules. The arbitrator shall apply the substantive law of the state
              of Florida except that the interpretation and enforcement of this
              arbitration provision shall be governed by the U.S. Federal
              Arbitration Act. To begin the arbitration process, a party must
              make a written demand therefore. Each part shall bear its own
              costs and attorneys’ fees. Any judgment upon the award rendered by
              the arbitrators may be entered in any court of competent
              jurisdiction. The arbitrator shall not have the power to award
              damages in connection with any Dispute in excess of actual
              compensatory damages and shall not multiply actual damages or
              award consequential, punitive or exemplary damages, and each party
              irrevocably waives any claim thereto. The agreement to arbitrate
              shall not be construed as an agreement to the joinder or
              consolidation of arbitration under this Agreement with arbitration
              of disputes or claims of any non-party, regardless of the nature
              of the issues or disputes involved. THIS AGREEMENT PROVIDES THAT
              ALL DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING
              ARBITRATION. YOU THUS GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT
              OR DEFEND YOUR RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO PARTICIPATE
              IN OR BRING CLASS ACTIONS. YOU ACKNOWLEDGE AND AGREE THAT YOU AND
              WE ARE EACH WAIVING THE RIGHT TO PARTICIPATE AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE
              PROCEEDING. Further, unless both you and we otherwise agree, the
              arbitrator may not consolidate more than one person's claims, and
              may not otherwise preside over any form of any class or
              representative proceeding. YOUR RIGHTS WILL BE DETERMINED BY A
              NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY.
            </Text>
            <Text tagName="h5">Parental Control Protections</Text>
            <Text tagName="p">
              Pursuant to 47 U.S.C. Section 230 (d), as amended, we hereby
              notify you that there are commercially available parental control
              protections (such as computer hardware, software or filtering
              services) that may assist you in limiting access to material that
              is harmful to minors. Information identifying current providers of
              such protections is available at{" "}
              <a
                href="http://internet-filter-review.toptenreviews.com"
                target="_blank"
                rel="noopener"
              >
                http://internet-filter-review.toptenreviews.com
              </a>
              .
            </Text>
            <Text tagName="h5">Class Action / Jury Trial Waiver</Text>
            <Text tagName="p">
              WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER
              THEY HAVE OBTAINED OR USED THE SERVICE FOR PERSONAL, COMMERCIAL OR
              OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’
              INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
              PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY
              GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER
              APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE
              ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU
              AGREE THAT, BY ENTERING INTO THIS AGREEMENT, YOU AND WE ARE EACH
              WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
              ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR
              OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.
            </Text>
            <Text tagName="h5">General</Text>
            <Text tagName="p">
              You agree that: (i) the Service shall be deemed solely based in
              the state of Florida; and (ii) this Service shall be deemed a
              passive web site and service that does not give rise to personal
              jurisdiction over ​us​, either specific or general, in
              jurisdictions other than in Florida. These Terms of Service shall
              be governed by the internal substantive laws of the state of
              Florida, without respect to its conflict of laws principles. ​The
              application of the United Nations Convention on Contracts for the
              International Sale of Goods is expressly excluded. ​These Terms of
              Service, together with the{" "}
              <Link to="/privacy-policy">Privacy Policy</Link> and any other
              legal notices published by us on the Service, shall constitute the
              entire agreement between you and us concerning this Service and
              supersedes any prior written or oral representations. If any
              provision of these Terms of Service is deemed invalid by a court
              of competent jurisdiction, the invalidity of such provision shall
              not affect the validity of the remaining provisions of these Terms
              of Service, which shall remain in full force and effect. No waiver
              of any term of these Terms of Service shall be deemed a further or
              continuing waiver of such term or any other term, and our failure
              to assert any right or provision under these Terms of Service
              shall not constitute a waiver of such right or provision. YOU AND
              WE AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
              THIS SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF
              ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
              BARRED. These Terms of Service may not be transferred or assigned
              by you, but may be assigned by ​us without restriction. Any
              attempted transfer or assignment in violation hereof shall be null
              and void. A printed version of these Terms of Service and of any
              notice given in electronic form shall be admissible in judicial or
              administrative proceedings based upon or relating to this
              agreement to the same extent and subject to the same conditions as
              other business documents and records originally generated and
              maintained in printed form. All rights not expressly granted
              herein are reserved to ​us​.
            </Text>
            <Text tagName="h5">Copyright and Trademark Notices</Text>
            <Text tagName="p">
              All contents of the Service are copyrighted &copy;{" "}
              {new Date().getFullYear() + " "}
              ClimateCheck&trade;, Inc. All rights reserved. Other product and
              company names may be trademarks or service marks of their
              respective owners.
            </Text>
            <Text tagName="p">
              IF YOU DO NOT AGREE TO THE TERMS STATED ABOVE OR TO ANY CHANGES
              MADE IN THESE TERMS, PLEASE EXIT THIS WEBSITE AND SERVICE
              IMMEDIATELY.
            </Text>
            <Text tagName="p">Last Updated: November 18, 2020</Text>
          </>
        }
      />
    </ProLayout>
  )
}

export default TermsService
