import React from "react"

import FormHeader from "../../../ui/FormHeader/FormHeader"

export interface InfoBlockProps {
  headline: string
  body: any
}

const InfoBlock: React.FC<InfoBlockProps> = ({
  headline = "",
  body = <></>,
}) => {
  return (
    <section className="infoBlock">
      <div className="contain">
        <FormHeader headline={headline} />
        <div className="bodyContent">{body}</div>
      </div>
    </section>
  )
}

export default InfoBlock
