import React from "react"
import ClimateCheckLogo from "../../../ui/ClimateCheckLogo/ClimateCheckLogo"

interface ProLayoutProps {
  className?: string
  headerTabs?: any
}

const ProLayout: React.FC<ProLayoutProps> = ({
  headerTabs = <></>,
  children,
}) => {
  return (
    <main className="proLayout">
      <header className="header">
        <ClimateCheckLogo />
        <div className="headerTabs">{headerTabs}</div>
      </header>
      {children}
    </main>
  )
}

export default ProLayout
