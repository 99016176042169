import React from "react"
import SEO from "@components/SEO"
import TermsService from "@components/TermsServicePage"
import "@styles/Components/termsService.scss"
import { graphql } from "gatsby"
import CorporativeFooter from "@components/global/CorporativeFooter"
import Footer from "@components/global/Footer"

const TermsServicePage = ({ data: { seo } }) => {
  const customSeo = {
    title: seo?.seo?.title || "Terms of Service",
    link: "https://climatecheck.com/terms-of-service",
    description:
      seo?.seo?.description ||
      "We offer the ​first definitive, easy-to-use property rating service to score real estate based on expected changes to the world’s climate.",
    ...seo?.seo,
  }
  return (
    <>
      <SEO seo={customSeo} />
      <TermsService />
      <div className="corporative-footer-contact">
        <p>
          Questions or feedback? Contact us at{" "}
          <a href="mailto:info@climatecheck.com" rel="noopener">
            info@climatecheck.com
          </a>
        </p>
      </div>
      <Footer/>
    </>
  )
}

export default TermsServicePage

export const query = graphql`
  query TermOfService {
    seo: sanityPagesSeo(slug: { current: { eq: "/terms-of-service" } }) {
      seo {
        title
        keywords
        description
      }
    }
  }
`
