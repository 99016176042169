import React from "react"

import { Text } from "@blueprintjs/core"

export interface FormHeaderProps {
  className?: string
  headline?: any
  content?: any
}

const FormHeader: React.FC<FormHeaderProps> = ({
  className = "",
  headline = "",
  content = "",
}) => {
  return (
    <header className={`formHeader ${className}`}>
      <Text className="formHeadline" tagName="h1">
        {headline}
      </Text>
      <Text className="formContent" tagName="p">
        {content}
      </Text>
    </header>
  )
}

export default FormHeader
